import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  autoHideDuration: 5000,
  open: false,
  message: '',
  severity: 'info',
};

const snackStatusSlice = createSlice({
  name: 'snackStatus',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      if (action?.payload?.autoHideDuration) state.autoHideDuration = action.payload.autoHideDuration;
      if (action?.payload?.open || action.payload.open === false) state.open = action.payload.open;
      if (action?.payload?.message) state.message = action.payload.message;
      if (action?.payload?.severity) state.severity = action.payload.severity;
    },
  },
});

export const snackStatusActions = snackStatusSlice.actions;

export default snackStatusSlice.reducer;
