import {configureStore} from '@reduxjs/toolkit';

// Import redux reducers
import AuthReducer from './auth/slice';
import UnitIndexReducer from './unitIndex/slice';
import KbiProductionReducer from './kbiProduction/slice';
import SearchReducer from './search/slice';
import SnackStatusReducer from './snackStatus/slice';
import SupplierReducer from './supplier/slice';
import UserReducer from './user/slice';

// Initialize Redux Store
const store = configureStore({
  reducer: {
    auth: AuthReducer,
    kbiProduction: KbiProductionReducer,
    search: SearchReducer,
    snackStatus: SnackStatusReducer,
    supplier: SupplierReducer,
    unitIndex: UnitIndexReducer,
    user: UserReducer,
  },
});

export default store;
