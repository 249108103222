import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  bookmarks: [],
  loading: true,
  margin: null,
  touched: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setBookmarks: (state, action) => {
      state.bookmarks = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMargin: (state, action) => {
      state.margin = action.payload;
    },
    setTouched: (state, action) => {
      state.touched = action.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
