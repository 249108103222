import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  authLoading: true,
  currentUser: null,
  signoutPending: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStatus: (state, action) => {
      if (typeof action?.payload?.authLoading !== 'undefined') state.authLoading = action.payload.authLoading;
      if (typeof action?.payload?.currentUser !== 'undefined') state.currentUser = action.payload.currentUser;
      if (typeof action?.payload?.signoutPending !== 'undefined') state.signoutPending = action.payload.signoutPending;
    },
    setAuthLoading: (state, action) => {
      state.authLoading = action.payload;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setSignoutPending: (state, action) => {
      state.signoutPending = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
