import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  business: {pending: true, data: null},
  individual: {pending: true, data: null},
  tier: null,
};

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setBusiness: (state, action) => {
      state.business = action.payload;
    },
    setIndividual: (state, action) => {
      state.individual = action.payload;
    },
    setTier: (state, action) => {
      state.tier = action.payload;
    },
  },
});

export const supplierActions = supplierSlice.actions;

export default supplierSlice.reducer;
