import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  data: null,
  lastUpdate: null,
  loading: true,
};

const unitIndexSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLastUpdate: (state, action) => {
      state.lastUpdate = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const unitIndexActions = unitIndexSlice.actions;

export default unitIndexSlice.reducer;
