import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {IonApp, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import store from './state/store';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables, Animate.css and swiper.js css*/
import './theme/variables.css';
import 'animate.css';
import 'swiper/css';
import 'swiper/css/pagination';

/* Setup MUI Theme */
import {ThemeProvider, createTheme} from '@mui/material/styles';
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6D6E71',
      light: '#9b9c9f',
      dark: '#424346',
      contrastText: '#ffffff',
      50: '#f0f1f1',
      100: '#e2e2e3',
      150: '#d3d4d4',
      200: '#c5c5c6',
      250: '#b6b7b8',
      300: '#a7a8aa',
      350: '#999a9c',
      400: '#8a8b8d',
      450: '#7c7d7f',
      500: '#6d6e71',
      550: '#626366',
      600: '#57585a',
      650: '#4c4d4f',
      700: '#414244',
      750: '#373739',
      800: '#2c2c2d',
      850: '#212122',
      900: '#161617',
      950: '#0b0b0b',
    },
    secondary: {
      main: '#ed1c24',
      light: '#ff5f4e',
      dark: '#b20000',
      contrastText: '#ffffff',
      50: '#fde8e9',
      100: '#fbd2d3',
      150: '#fabbbd',
      200: '#f8a4a7',
      250: '#f68e92',
      300: '#f4777c',
      350: '#f26066',
      400: '#f14950',
      450: '#ef333a',
      500: '#ed1c24',
      550: '#d51920',
      600: '#be161d',
      650: '#a61419',
      700: '#8e1116',
      750: '#770e12',
      800: '#5f0b0e',
      850: '#47080b',
      900: '#2f0607',
      950: '#180304',
    },
    background: {
      paper: '#ffffff',
      default: '#eeeeee',
    },
  },
  shape: {borderRadius: 4},
});

// Import main App component
const App = React.lazy(() => import('./App'));

// Placeholder to add custom ionic config object; https://ionicframework.com/docs/react/config
// Mode set to 'md' to apply Material Design to entire application, even on iOS devices.
setupIonicReact({mode: 'md'});

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
  <ThemeProvider theme={theme}>
    <ReduxProvider store={store}>
      <Suspense fallback={<div />}>
        <IonApp>
          <IonReactRouter>
            <App />
          </IonReactRouter>
        </IonApp>
      </Suspense>
    </ReduxProvider>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
