import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  leaseRate: null,
  manufacturers: null,
  metalValue: null,
};

const kbiProductionSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setLeaseRate: (state, action) => {
      state.leaseRate = action.payload;
    },
    setManufacturers: (state, action) => {
      state.manufacturers = action.payload;
    },
    setMetalValue: (state, action) => {
      state.metalValue = action.payload;
    },
  },
});

export const marketActions = kbiProductionSlice.actions;

export default kbiProductionSlice.reducer;
